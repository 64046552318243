/** @jsxImportSource theme-ui */
import { Box, jsx } from 'theme-ui'
import { MdStar, MdStarBorder, MdStarHalf } from 'react-icons/md'
import { useTheme } from 'src/hooks/use-theme'
import { Flex } from 'theme-ui'

type Props = {
  rating: number
  size?: number
  color?: string
  className?: any
  onClickStar?: (star: '1' | '2' | '3' | '4' | '5') => void
}

export function StarRatings(props: Props) {
  const { rating, size = 18, className, onClickStar } = props

  const color = '#edd46f'

  const { colors } = useTheme()

  return (
    <Flex className={className}>
      {new Array(5).fill('').map((_, i) => {
        let Star = MdStar
        const nthStar = i + 1
        if (rating > nthStar - 0.5) {
          Star = MdStar
        } else if (rating > nthStar - 1 && rating <= nthStar - 0.5) {
          Star = MdStarHalf
        } else {
          Star = MdStarBorder
        }
        return (
          <Flex
            // @ts-ignore
            onClick={() => onClickStar?.(`${i + 1}`)}
            sx={{ alignItems: 'flex-start' }}
            key={i.toString()}
          >
            <Star
              size={size}
              sx={{ mx: (theme) => theme.space[1] / 2 }}
              // color={colors[color as keyof typeof colors] ?? color}
              color={color}
            />
          </Flex>
        )
      })}
    </Flex>
  )
}
