/** @jsxImportSource theme-ui */
import { Flex, Text } from 'theme-ui'

import Link from 'next/link'
import { ComponentPropsWithoutRef, memo } from 'react'
import { Box, Button, Card } from 'theme-ui'

import { ShopName } from 'src/constants'
import { useTheme } from 'src/hooks/use-theme'
import { StarRatings } from './Star-Ratings'

import { keyframes } from '@emotion/react'

import { Styled } from '~/components/styled'
import { ContentfulImage as Image } from '~/features/image/contentful-image'

const fadeIn = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`

type Props = {
  title: string
  price: string
  images: ComponentPropsWithoutRef<typeof Image>[]
  handle: string
  imageHeightPercentage: string[] | undefined
  maxHeight: string[] | undefined
  showReviewsCount: boolean
  reviewsCount?: number
  starRating?: number
  ctaText?: string
  sizes?: string
  onClick?: () => void
}

const maxH = ['300px']

const CollectionProductCard = memo(function GridCard(props: Props) {
  const {
    title,
    price,
    images,
    handle,
    imageHeightPercentage = '130%',
    maxHeight = maxH,
    showReviewsCount = true,
    reviewsCount = 0,
    starRating,
    ctaText,
    sizes = "(max-width: 600px) 100vw, 600px",
    onClick,
  } = props

  const { colors } = useTheme()

  const renderReviewsCount = () => {
    if (!showReviewsCount || !reviewsCount || !starRating) return null

    return (
      <Box>
        <Flex
          sx={{
            justifyContent: 'center',
            flexDirection: ['column', 'row'],
            alignItems: ['center'],
          }}
        >
          {<StarRatings rating={starRating} />}
          <Text sx={{ fontSize: 1, mx: 1, color: 'gray' }}>
            {reviewsCount} Reviews
          </Text>
        </Flex>
      </Box>
    )
  }

  return (
    <Link
      href={`/products/${handle}`}
      sx={{
        variant: 'links.default',
        textDecoration: 'none',
        // '&:hover .product-image-0': {
        //   opacity: 1,
        //   transform: 'scale(1.1) translateY(-3px)',
        // },
        '&:hover .product-image-1': {
          display: [null, 'block'],
        },
        '&:hover .button': {
          // backgroundColor: 'text',
          // color: 'background',
          // borderColor: 'text',
          color: 'secondary',
        },
        cursor: 'pointer',
      }}
      onClick={
        // onClick
        (e) => {
          if (onClick) {
            // // console.log('clicked collection product card')
            e?.preventDefault?.()
            onClick()
          }
        }
      }
    >
      <Card variant="collectionProduct">
        <Box
          sx={{
            borderRadius: 3,
            overflow: 'hidden',
            position: 'relative',
            height: '0px',
            maxHeight,
            paddingBottom: imageHeightPercentage,
            bg: 'mutedSecondary',
          }}
        >
          {images?.map((props, i) => (
            <Image
              fill
              sizes={sizes}
              key={i.toString()}
              {...props}
              alt={`${ShopName} ${title}`}
              className={`product-image-${i}`}
              sx={{
                transition: 'transform .25s .1s ease',
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                display: i > 0 ? 'none' : undefined,
                transform: 'scale(1)',
                animation: i > 0 ? `${fadeIn} .25s ease alternate` : undefined,
                objectFit: 'cover',
              }}
              loading="lazy"
            />
          ))}
          <Box
            sx={{
              position: 'absolute',
              display: ['none', 'block'],
              bottom: 0,
              left: 0,
              right: 0,
              p: 3,
            }}
          >
            <Button
              className="button"
              sx={{
                display: 'block',
                width: '100%',
                bg: 'background',
                color: 'text',
                borderColor: 'background',
                // textTransform: 'uppercase',
                // letterSpacing: 2,
                // fontSize: 2,
                fontWeight: '600',
                transition: 'all .25s ease-in-out',
                px: 1,
                // '&:hover': {
                //   color: 'background',
                //   backgroundColor: 'text',
                //   borderColor: 'text',
                // },
              }}
            >
              <Text variant="text.spacedOut" sx={{ letterSpacing: 0 }}>
                {ctaText ?? `View – ${price}`}
              </Text>
            </Button>
          </Box>
        </Box>
      </Card>
      <Box py={4}>
        <Styled.h3 sx={{ mt: 1, textAlign: 'center', fontSize: [3, 5] }}>
          {title}{' '}
          <Text
            as="span"
            sx={{
              fontWeight: 'normal',
              display: [null, 'none'],
              fontSize: [3, 4],
            }}
          >
            {` | ${price}`}
          </Text>
        </Styled.h3>
        {renderReviewsCount()}
      </Box>
    </Link>
  )
})

export default CollectionProductCard
