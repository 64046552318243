import Head from 'next/head'
import React from 'react'

type Props = {
  url: string
  name?: string | null
  type?: 'article' | 'website'
  title: string
  desc: string
  image: string
  locale: string
}
const Facebook = ({
  url,
  name = null,
  type = 'website',
  title,
  desc,
  image,
  locale,
}: Props) => (
  <Head>
    {name && <meta property="og:site_name" content={name} />}
    <meta property="og:locale" content={locale} />
    <meta property="og:url" content={url} />
    <meta property="og:type" content={type} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={desc} />
    <meta property="og:image" content={image} />
    <meta property="og:image:alt" content={desc} />
  </Head>
)

export default Facebook
