import Head from 'next/head'
import React from 'react'

type Props = {
  type?: string
  username: string
  title: string
  desc: string
  image: string
}

const Twitter = ({ type, username, title, desc, image }: Props) => (
  <Head>
    {username && <meta name="twitter:creator" content={username} />}
    <meta name="twitter:card" content={type} />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={desc} />
    <meta name="twitter:image" content={image} />
    <meta name="twitter:image:alt" content={desc} />
  </Head>
)

export default Twitter
